import React from 'react'
import { graphql } from 'gatsby'

import EditorPage from '../components/editorPage'
import { Editor } from '../types'

type EditorTemplateProps = {
  data: {
    Content: {
      getEditors: {
        items: Editor[]
      }
    }
  }
}

const EditorTemplate: React.FC<EditorTemplateProps> = props => {
  const editor = props.data.Content.getEditors.items[0];

  return <EditorPage editor={editor} />
}

export const query = graphql`
  query($slug: String!) {
    Content {
      getEditors(limit: 1, filter: {slug: {eq: $slug}}) {
        items {
          id
          slug
          firstName
          lastName
          bio
          email
          photo
          socialLinks {
            type
            value
          }
          slug
        }
      }
    }
  }
`

export default EditorTemplate
